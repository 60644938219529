<template>
  <div :style='`width:${width}; height:${height}`' v-loading="loading">
    <vue-apex-charts ref="chart" height="100%" width="100%" :options="options" :series="series" type="donut" class="custom_chart"></vue-apex-charts>
    <slot></slot>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import VisibilityMixin from '@/mixins/visibility'
import FilterBuilder, { EComponentTypes } from '../utils'

export default {
  name: 'a-donutchart',
  components: {
    VueApexCharts
  },
  inject: {
    getRegistryRecordId: { default: () => {} },
    forceUpdateSettingsPanel: { default: () => () => {} }
  },
  mixins: [mixin, registryMixin, VisibilityMixin],
  props: {
    size: {
      frozen: true
    },
    align: {
      frozen: true
    },
    margin: {
      frozen: true
    },
    readonly: {
      frozen: true
    },
    hiddenCondition: {
      frozen: true
    },
    block: {
      frozen: true
    },
    isRequired: {
      frozen: true
    },
    wrapper: {
      frozen: true
    },
    alwaysActive: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    editorChart: {
      type: Object,
      editor: 'Chart',
      default: () => {
        return {
          type: 'plugin',
          colors: ['#69B3E7', '#3F95DA', '#0977CB', '#0058B9', '#0039A3', '#001489']
        }
      },
      options: { type: 'donut' }
    },
    params: {
      type: String,
      description: 'Доп. параметры'
    },
    total: {
      type: Boolean,
      description: 'Разместить общий итог'
    },
    width: {
      type: String,
      description: 'Ширина',
      default: '100%'
    },
    height: {
      type: String,
      description: 'Высота',
      default: '100%'
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    }
  },
  data () {
    return {
      loaderInstance: undefined,
      loading: false,
      categories: [],
      options: {
        title: {},
        legend: {},
        chart: {
          stacked: true,
          toolbar: {
            show: true,
            tools: {
              download: true
            }
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: this.total,
                total: {
                  showAlways: true,
                  show: true,
                  label: 'Всего'
                }
              }
            }
          }
        },
        // dataLabels: {
        //   style: {
        //     colors: ['#F44336', '#E91E63', '#9C27B0']
        //   }
        // },
        colors: undefined,
        xaxis: {
        },
        fill: {
          opacity: 1

        }
      },
      series: []
    }
  },
  computed: {
    dataFilters () {
      if (this.editorChart.type === 'plugin') {
        let filters = []
        if (this.filters) {
          this.filters.forEach((item) => {
            if (!item.type || item.type === 'field') {
              if (this.getModel()[item.attribute] && item.alias) {
                filters.push(`${item.alias}=${this.getModel()[item.attribute]}`)
              }
            } else if (item.type === 'constant' && item.alias) {
              filters.push(`${item.alias}=${item.attribute}`)
            }
          })
        }
        return filters
      } else {
        const builder = new FilterBuilder(
          this.filters,
          this.getModel(),
          this.$store,
          EComponentTypes.donutChartApiQl
        )

        const filters = builder.buildAsApiQl()
        if (filters.length === 0 && this.isEditor()) {
          return {
            limit: 10
          }
        }
        if (filters.length > 0 && this.isEditor()) {
          return {
            where: {
              and: [...filters]
            },
            limit: 20
          }
        }
        if (filters.length > 0) {
          return {
            where: {
              and: [...filters]
            }
          }
        }

        return {}
      }
    }
  },
  watch: {
    dataFilters () {
      this.loadData()
    },
    editorChart: {
      handler (value) {
        this.options.title = Object.assign({}, this.options.title, value.title)
        this.options.legend = Object.assign({}, this.options.legend, value.legend)
        this.options.colors = value.colors
      },
      immediate: true
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  async mounted () {
    if ((this.editorChart.type === 'plugin' && !this.editorChart.plugin) ||
            (this.editorChart.type === 'extended_object' && !this.editorChart.extendObject)
    ) {
      return false
    }
    this.onVisibilityChange(() => {
      this.$nextTick(() => {
        if (this.$refs.chart && this.$refs.chart.chart && this.$refs.chart.$el.children[0].offsetHeight === 0) {
          this.$refs.chart.chart.update()
        }
      })
    })
    setTimeout(async () => {
      await this.loadData()
    }, 700)
  },
  methods: {
    async loadData () {
      // if (!this.loaderInstance) {
      //   this.loaderInstance = this.$loading({ target: this.$el })
      // }
      this.loading = true
      if (this.editorChart.type === 'plugin') {
        let data = await this.$http.get(`${this.$config.api}/registryservice/plugins/execute/${this.editorChart.plugin}?recordId=${this.getRegistryRecordId() || 0}&${this.dataFilters.join('&')}&${this.params || ''}`)
          .finally(() => {
            // this.loaderInstance && this.loaderInstance.close()
            // this.loaderInstance = undefined
            this.loading = false
          })
        if (data.data) {
          this.$refs.chart.updateOptions({
            labels: data.data.categories.map(item => item || '')
          })
          this.series = data.data.series
        }
      } else if (this.editorChart.type === 'extend_object') {
        // let data = await this.$http.get(`${this.$config.api}/datawarehouseservice/extended_object/${this.editorChart.extendObject}?${this.dataFilters.join('&')}&${this.params || ''}`)
        let data = await this.$http
          .post(`${this.$config.api}/datawarehouseservice/extended_object/${this.editorChart.extendObject}`, this.dataFilters, { hideNotification: true })
          .finally(() => {
            // this.loaderInstance && this.loaderInstance.close()
            // this.loaderInstance = undefined
            this.loading = false
          })
        let series = this.getExtendedObjectSeries(data.data)
        this.$refs.chart.updateOptions({
          labels: this.getExtendedObjectCategories(data.data)
        })
        this.series = series
      } else {
        // this.loaderInstance && this.loaderInstance.close()
        // this.loaderInstance = undefined
        this.loading = false
      }
    },
    getExtendedObjectSeries (data) {
      if (this.editorChart.isHorizontal) {
        this.options.colors = []
        let series = []
        this.editorChart.seriesFields.forEach((item) => {
          series.push(parseInt(data[0][item.field] || 0))
          this.options.colors.push(item.color)
        })
        this.$refs.chart.updateOptions({
          colors: this.options.colors
        })
        return series
      } else {
        return data.map(item => parseInt(item[this.editorChart.valueField.name] || 0))
      }
    },
    getExtendedObjectCategories (data) {
      if (this.editorChart.isHorizontal) {
        return this.editorChart.seriesFields.map(item => item.name + '')
      } else {
        return data.map(item => item[this.editorChart.categorie.name] + '')
      }
    }
  }
}
</script>

<style>
.custom_chart .apexcharts-legend-series {
  padding: 6px;
}
</style>
